<template>
  <ui-component-modal
    :modalTitle="'Send mail'"
    :onClickSave="sendMail"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :isSaving="isSending"
    :buttonLabelConfirm="'Send'"
    :disableSaveButton="
      selectedMailType === '' || isSending || !valideMailSettings
    "
    modalSize="large"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div v-if="!isSendingError && !isSendingSuccess">
        <table v-if="selectedMail" class="table is-fullwidth">
          <tbody>
            <tr>
              <td>From</td>
              <td>
                <div v-if="selectedMail.FromEmail.length === 0">
                  <span
                    key="mailaddressInput"
                    class="wrapper-input has-padding-right"
                  >
                    <input
                      ref="mailaddressInput"
                      type="text"
                      v-model="fromEmail"
                      class="input input-mailaddress"
                      placeholder="Add mailaddress"
                      @keyup.enter="addFromEmail"
                    />
                    <a @click="addFromEmail" class="button is-small is-success">
                      <span class="icon is-small">
                        <font-awesome-icon :icon="['fas', 'plus']" />
                      </span>
                    </a>
                  </span>
                </div>

                <div
                  v-if="selectedMail.FromEmail.length > 0"
                  class="tags has-addons"
                >
                  <span class="tag is-link">{{ selectedMail.FromEmail }}</span>
                  <a @click="deleteFromEmail" class="tag is-delete"></a>
                </div>
              </td>
            </tr>
            <tr>
              <td>To</td>
              <td>
                <mail-input
                  :mailaddresses="mailAddresses"
                  @mailinputChecked="setMailInputEmpty"
                  @mailaddressAdded="addMailAddress"
                  @mailaddressDeleted="deleteMailAddress"
                />
              </td>
            </tr>
            <tr>
              <td>BCC</td>
              <td>
                <mail-input
                  :mailaddresses="bccAddresses"
                  @mailaddressAdded="addBCCAddress"
                  @mailaddressDeleted="deleteBCCAddress"
                />
              </td>
            </tr>

            <tr>
              <td width="100">Mail type</td>
              <td class="has-text-right">
                <div class="select" :class="{ 'is-loading': isLoadingMails }">
                  <select v-model="selectedMail">
                    <option :selected="selectedMailType === ''">Blank</option>
                    <option
                      v-for="(mail, index) in mails"
                      :key="index"
                      :value="mail"
                      :selected="mail.MailType === selectedMailType"
                      >{{ mail.MailType }}</option
                    >
                  </select>
                </div>
              </td>
            </tr>

            <tr>
              <td>Subject</td>
              <td>
                <input
                  type="text"
                  v-model="selectedMail.Subject"
                  class="input"
                />
              </td>
            </tr>
            <tr>
              <td>Mail</td>
              <td>
                <div class="control">
                  <ui-text-editor
                    :key="'editor' + selectedMail.Id"
                    v-model="selectedMail.Body"
                  ></ui-text-editor>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <section v-if="isSendingSuccess" class="section">
        <div class="container has-text-centered">
          <success-animation />

          <div class="title">Invoice sent</div>
        </div>
      </section>

      <section v-if="isSendingError" class="section">
        <div class="container has-text-centered">
          <error-animation />

          <div class="title">Oops something went wrong</div>
        </div>
      </section>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState } from 'vuex'
import invoiceProvider from '@/providers/invoice'
import mailProvider from '@/providers/mail'

const MailInput = () => import('@/components/UI/Components/MailInput')
const ErrorAnimation = () => import('@/components/UI/Components/ErrorAnimation')
const SuccessAnimation = () =>
  import('@/components/UI/Components/SuccessAnimation')

export default {
  components: {
    'mail-input': MailInput,
    'error-animation': ErrorAnimation,
    'success-animation': SuccessAnimation,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      fromEmail: '',
      invoiceSettings: null,
      isLoadingMails: false,
      isSendingError: false,
      isSendingSuccess: false,
      isSending: false,
      bccAddresses: [],
      mailAddresses: [],
      mails: [],
      mailinputEmpty: true,
      selectedMail: null,
    }
  },

  computed: {
    ...mapState('invoiceStore', ['invoice']),
    ...mapState('locationStore', ['location']),

    selectedMailType() {
      let type = ''

      if (this.selectedMail && this.selectedMail !== null) {
        type = this.selectedMail.MailType
      }

      return type
    },

    valideMailSettings() {
      return true
    },
  },

  created() {
    this.getInvoiceMails()
    this.getLocationInvoiceSettings()
  },

  methods: {
    addFromEmail() {
      this.selectedMail.FromEmail = this.fromEmail
      this.fromEmail = ''
    },

    deleteFromEmail() {
      this.selectedMail.FromEmail = ''
    },

    addMailAddress(address) {
      this.mailAddresses.unshift(address)
    },

    deleteMailAddress(address) {
      let addressIndex = this.mailAddresses.findIndex((t) => t === address)
      if (addressIndex > -1) {
        this.mailAddresses.splice(addressIndex, 1)
      }
    },

    addBCCAddress(address) {
      this.bccAddresses.unshift(address)
    },

    deleteBCCAddress(address) {
      let addressIndex = this.bccAddresses.findIndex((t) => t === address)
      if (addressIndex > -1) {
        this.bccAddresses.splice(addressIndex, 1)
      }
    },

    setMailInputEmpty(value) {
      this.mailinputEmpty = value
    },

    getInvoiceMails() {
      let self = this

      if (!self.isLoadingMails) {
        self.isLoadingMails = true

        invoiceProvider.methods
          .getInvoiceMails(self.invoice.Id)
          .then((response) => {
            if (response.status === 200) {
              self.mails = response.data
              self.selectedMail = self.mails[0]

              self.bccAddresses =
                self.selectedMail.Cc.length > 0
                  ? self.selectedMail.Cc.trim().split(',')
                  : []
              self.mailAddresses = self.selectedMail.ToEmail.trim().split(',')
            }
          })
          .finally(() => {
            self.isLoadingMails = false
          })
      }
    },

    getLocationInvoiceSettings() {
      let self = this
      invoiceProvider.methods
        .getLocationInvoiceSettings(this.location.Id)
        .then((response) => {
          if (response.status === 200) {
            this.invoiceSettings = response.data
            this.selectedMail.FromEmail = this.invoiceSettings.DefaultSenderAddress
          }
        })
        .finally(() => {})
    },

    sendMail() {
      let self = this

      if (!self.isSending) {
        self.isSending = true

        let mail = {
          FromEmail: self.selectedMail.FromEmail,
          ToEmail: self.mailAddresses.join(','),
          CC: self.bccAddresses.join(','),
          Subject: self.selectedMail.Subject,
          Body: self.selectedMail.Body,
        }

        mailProvider.methods
          .sendInvoiceMail(self.invoice.Id, mail)
          .then((response) => {
            self.isSendingSuccess = true

            setTimeout(() => {
              self.onClickCancel()
            }, 1000)
          })
          .catch((e) => {
            self.isSendingError = true
          })
          .finally(() => {
            self.isSending = false
          })
      }
    },
  },
}
</script>
