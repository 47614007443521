import axios from "axios";
import store from "../store";

const apiUrl = process.env.VUE_APP_API_BASE_URL + "/api";
const apiToken = process.env.VUE_APP_API_KEY;

export default {
  methods: {
    async getLocationReservationMailTexts(locationId) {
      const response = await axios.get(
        `${apiUrl}/v1/mail/text/location/${locationId}/reservation`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: apiToken,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async getReservationMailTextsByReservationId(reservationId, mailType = 'None') {
      const response = await axios.get(
        `${apiUrl}/v1/mail/text/reservation/${reservationId}`,
        {
          params: {
            mailtype: mailType
          },
          headers: {
            "Content-type": "application/json",
            apiToken: apiToken,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async saveLocationReservationMailTexts(locationId, mailTexts) {
      const response = await axios.put(
        `${apiUrl}/v1/mail/text/location/${locationId}/reservation`,
        mailTexts,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: apiToken,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async sendInvoiceMail(
      invoiceId,
      mail
    ) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/mail/invoice/${invoiceId}/send`,
        JSON.stringify(mail),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async sendReservationMail(
      reservationId,
      mail
    ) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/mail/reservation/${reservationId}/send`,
        JSON.stringify(mail),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationMailLog(reservationId) {
      const response = await axios.get(
        `${apiUrl}/v1/mail/reservation/${reservationId}/log`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: apiToken,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async saveMailText(mailText) {
      const response = await axios.put(
        `${apiUrl}/v1/mail/${mailText.Id}`,
        mailText,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: apiToken,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    }
  }
}
